import { Button, Modal, Spinner } from 'react-bootstrap'
import api from '@/api'
import { useState } from 'react'

interface Code { 
  _id: string,
  code: string,
  name: string,
}

export default function AdminsDeleteCode(
  { show, setShow, prop }: { show: boolean, setShow: any, prop: Code}
) {
  const [loading, setLoading] = useState(false)

  function handleDeleteCode(_id: string) {
    setLoading(true);
    return api.admins.branches.codes.deleteCode({
      _id: _id
    }).then((res: any) => {
      alert('삭제되었습니다')
      window.location.reload()
    }).catch((e) => {
      console.error(e)
    }).finally(() => {
      setLoading(false)
    })
  }

  return (
    <Modal
      show={show} onHide={() => setShow(false)}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>코드삭제 확인</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <li>코드: {prop.code}</li>
          <li>이름: {prop.name}</li>
        </p>
        <p>
          한번 삭제된 코드는 복구가 불가합니다. <br />
          <b>삭제하시겠습니까?</b>
        </p>
      </Modal.Body>
      <Modal.Footer>
        {
          loading ? (
            <Button
              variant="secondary"
              className="d-flex align-items-center gap-2"
              disabled
            >
              <span>삭제중</span>
              <Spinner animation="border" size="sm" />
            </Button>
          ) : (
            <>
              <Button variant="secondary" onClick={() => setShow(false)}>취소</Button>
              <Button variant="danger" onClick={() =>
                handleDeleteCode(prop?._id)
              }>
                삭제
              </Button>
            </>
          )
        }
      </Modal.Footer>
    </Modal>
  )
}