import axios, { AxiosRequestConfig } from 'axios'
import { API_BASE_URL } from '@/consts/api'
const axiosConfig: AxiosRequestConfig = {
  baseURL: API_BASE_URL
}
const axiosInstance = axios.create(axiosConfig)

try {
  const token = sessionStorage.getItem("token")
  axios.defaults.headers.common['Authorization'] = `${token}`
  axiosInstance.defaults.headers.common['Authorization'] = `${token}`
  axiosInstance.interceptors.response.use((response: any) => {
    console.info(`[API] ${response.config.method} ${response.config.url} ${response.status} ${response.statusText}`)
    return response;
  }, (error: any) => {
    console.error(`[API] ${error.config.method} ${error.config.url} ${error.response.status} ${error.response.statusText}`)
    window.location.href = "/login"
    return Promise.reject(error)
  })
} catch (e) {
  console.error(e)
}

const agencyApi = {
  branches: {
    codes: {
      listCodes: (params: any) => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'get',
          url: `/web-agency/branches/codes?per_page=10&page=${params.page}&query=${params.query}`,
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),
      countAllCodes: () => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'get',
          url: '/web-agency/branches/codes/count-all',
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),
      createCode: (params: any) => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'post',
          url: `/web-agency/branches/codes`,
          data: params
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),
      getCode: (params: any) => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'get',
          url: `/web-agency/branches/codes/${params._id}`,
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),
      patchCode: (params: any) => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'patch',
          url: `/web-agency/branches/codes/${params._id}`,
          data: params
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),
    }
  },
  hosts: {
    printers: {
      createPrinter: (params: any) => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'post',
          url: `/web-agency/hosts/printers`,
          data: params
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),
      listPrinters: (params: any) => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'get',
          url: `/web-agency/hosts/printers?per_page=10&page=${params.page}&query=${params.query}`,
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),
      listPrintersByAgencyId: (params: any) => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'get',
          url: `/web-agency/hosts/printers/by-agency-id?query=${params.query}`,
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),
      countAllPrinters: () => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'get',
          url: '/web-agency/hosts/printers/count-all',
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),
      getPrinter: (params: any) => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'get',
          url: `/web-agency/hosts/printers/${params._id}`,
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),
      patchPrinter: (params: any) => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'patch',
          url: `/web-agency/hosts/printers/${params._id}`,
          data: params
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),

    }
  },
  users: {
    admins: {
    },
    customers: {
      countAllCustomers: () => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'get',
          url: '/web-backoffice/users/customers/count-all',
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),
      listCustomers: (params: any) => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'get',
          url: `/web-backoffice/users/customers?per_page=${params.per_page || "10"}&page=${params.page}&query=${params.query}`,
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),
      getCustomer: (params: any) => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'get',
          url: `/web-backoffice/users/customers/${params._id}`,
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),
    },
    agencies: {
      me: () => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'get',
          url: `/web-agency/users/agencies/me`,
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),


      listAgencies: (params: any) => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'get',
          url: `/web-agency/users/agencies?per_page=${params.perPage ? params.perPage : 10}&page=${params.page}&query=${params.query}`,
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),
      countAllAgencies: () => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'get',
          url: '/web-backoffice/users/agencies/count-all',
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),
      getAgency: (params: any) => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'get',
          url: `/web-backoffice/users/agencies/${params._id}`,
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),
      createAgency: (params: any) => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'post',
          url: `/web-backoffice/users/agencies`,
          data: params
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),
      patchAgency: (params: any) => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'patch',
          url: `/web-backoffice/users/agencies/${params._id}`,
          data: params
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),
      deleteAgency: (params: any) => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'delete',
          url: `/web-backoffice/users/agencies/${params._id}`,
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),
      changePassword: (params: any) => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'patch',
          url: `/web-backoffice/users/agencies/password/${params._id}`,
          data: params
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),
    }
  },
  payments: {
    logs: {
      listLogs: (params: any) => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'get',
          url: `/web-agency/payments/logs?per_page=10&page=${params.page}&query=${params.query}`,
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),
      countAllLogs: () => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'get',
          url: '/web-agency/payments/logs/count-all',
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),
      getLogsByAgency: (params: any) => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'get',
          url: `/web-agency/payments/logs/by-agency-id?dateStart=${params.dateStart}&dateEnd=${params.dateEnd}`,
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),

    }
  },

}

export default agencyApi