import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import { Button, Col, Container, Row, Alert } from 'react-bootstrap'

import Pagination from 'react-js-pagination'

import api from '@/api'
import { useEffect, useState } from 'react'

import dayjs from 'dayjs'
import { useNavigate, useLocation } from 'react-router-dom'
import queryString from 'query-string'


const AdminsPaymentsLogsList = () => {
  const navigation = useNavigate()
  const { search } = useLocation()
  const [resultCount, setResultCount] = useState(0)
  const [query, setQuery] = useState('')
  const [page, setPage] = useState(1)
  const [results, setResults] = useState([])
  const [searchCount, setSearchCount] = useState(0)

  const fetch = (params: any) => {
    api.admins.payments.logs.listLogs({
      page: params.page,
      query: params.query,
    }).then((res: any) => {
      setResults(res.result.logs)
      setSearchCount(res.result.total)
    }).catch((e) => {
      console.error(e)
      alert("서버오류가 발생하였습니다")
    })
  }

  const handlePageChange = (page: number) => {
    setPage(page)
    return window.location.href = (`/admins/payments/logs/list?page=${page}&query=${query}`)
  }

  useEffect(() => {
    const values = queryString.parse(search)
    const queryPage = values.page ? Number(values.page) : 1
    const queryQuery = values.query ? String(values.query) : ''
    setPage(prevPage => queryPage)
    setQuery(prevQuery => queryQuery)
    api.admins.payments.logs.countAllLogs().then((res: any) => {
      setResultCount(res.result.count)
      fetch({ page: queryPage, query: queryQuery })
    }).catch((e) => {
      console.error(e)
      alert("서버오류가 발생하였습니다")
    })
  }, [search])


  return (
    <>
      <Container fluid>
        <Row className="mt-2">
          <p className="text-secondary">
            결제내역
          </p>
        </Row>
        <Row className="bg-white mb-4">
          <Row className="mt-2 mb-2">
            <Col>
              전체 내역: <b>{resultCount.toLocaleString()}</b>건 / 검색결과: <b>{searchCount.toLocaleString()}</b>건
            </Col>
          </Row>

          <Row className="mb-2">
            <Row className="mb-2">
              <Col sm={12} lg={8}>
                <Form.Control
                  type="text"
                  placeholder="검색어를 입력하세요 (이름/회원ID/카드번호/승인번호)"
                  size="sm"
                  value={query}
                  onChange={(e) => { setQuery(e.target.value) }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  size="sm"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    return window.location.href = (`/admins/payments/logs/list?page=1&query=${query}`)
                  }}
                >검색</Button>
              </Col>
            </Row>
          </Row>


        </Row>
        <Row className="bg-white pt-2 pb-2 ">
          <Col>
          </Col>
          <Col
            className="d-flex justify-content-end"
          >
          </Col>
        </Row>
        <Row className="bg-white">
          <Container>
            <Alert variant='warning'>
              <li>
                전액 포인트를 이용하여 결제한 경우 승인번호가 존재하지 않아 00000000으로 표시됩니다.
              </li>
              <li>
                결제취소된 카드내역은 KICC 이지페이 오피스에서 확인할 수 있습니다.
              </li>
            </Alert>
          </Container>
          <Table bordered hover responsive="lg" className="table-sm">
            <thead>
              <tr>
                <th>고객명</th>
                <th>카드번호</th>
                <th>카드승인번호</th>
                <th>결제금액</th>
                <th>포인트사용</th>
                <th>승인금액</th>
                <th>결제가맹점</th>
                <th>결제일시</th>
              </tr>
            </thead>
            <tbody>
              {
                results.map((log: any) => {
                  return (
                    <tr>
                      <td>
                        <Button size="sm" variant="secondary"
                          onClick={() => { navigation(`/admins/users/customers/${log.customerId}`); }}
                        >
                          {log.customerName || "알수없음"}
                        </Button>
                      </td>
                      <td>{log.cardNo || ""}</td>
                      <td>{log.approvalNo || ""}</td>
                      <td>{log.amount.toLocaleString() || ""}원</td>
                      <td>{log.point.toLocaleString() || "0"}</td>
                      <td>{log.cardAmount.toLocaleString()}원</td>
                      <td>
                        <Button
                          variant="secondary"
                          size="sm"
                          onClick={() => {
                            return window.location.href = `/admins/users/agencies/${log.agencyId}`
                          }}>
                          {log.agencyName || ""}
                        </Button>
                      </td>
                      <td>{dayjs(log.createdAt).format('YYYY-MM-DD HH:mm:ss')}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
        </Row>
        <Row className="bg-white pb-4">
          <Col>
            <Pagination
              activePage={page}
              itemsCountPerPage={10}
              totalItemsCount={searchCount}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
            />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default AdminsPaymentsLogsList