import { Button, Form, Container, Row, Col } from 'react-bootstrap'


import api from '@/api'
import { useEffect, useState } from 'react'
import { isIPv4 } from 'is-ip'


const AdminsHostsPrintersCreate = () => {

  const [agencies, setAgencies] = useState([])

  const [printerInfo, setPrinterInfo] = useState({
    agencyId: '',
    name: '',
    ipAddress: '',
    port: '9100',
  })

  const handleBtnClicked = () => {
    if (printerInfo.agencyId === "")
      return alert('가맹점을 선택하세요.')

    if (printerInfo.name.length < 2)
      return alert('프린터 이름을 2자리 이상 입력해주세요.')

    if (!isIPv4(printerInfo.ipAddress))
      return alert(`IP주소가 올바르지 않습니다.`)

    if (isNaN(parseInt(printerInfo.port)))
      return alert(`포트번호가 올바르지 않습니다.`)
    if (parseInt(printerInfo.port) < 1 || parseInt(printerInfo.port) > 65535)
      return alert(`포트번호가 올바르지 않습니다.`)

    const payload = {
      agencyId: printerInfo.agencyId.trim(),
      name: printerInfo.name.trim(),
      ipAddress: printerInfo.ipAddress.trim(),
      port: printerInfo.port.trim(),
    }

    return api.admins.hosts.printers.createPrinter(payload).then((res: any) => {
      if (!res.error.ok) {
        console.info(res)
        return alert(res.error.message)
      } else {
        alert('프린터가 생성되었습니다.')
        return window.location.href = (`/admins/hosts/printers/list`)
      }
    }).catch((e) => {
      console.error(e);
      return alert(`에러가 발생하였습니다`);
    })

  }

  useEffect(() => {
    api.admins.users.agencies.listAgencies({
      page: 1, perPage: 9999, query: ''
    }).then((res: any) => {
      setAgencies(res.result.agencies)
    }).catch((e) => {
      console.error(e);
      alert(`에러가 발생하였습니다`);
      return window.history.back();
    })

  }, [])


  return (
    <>
      <Container fluid>
        <Row className="mt-2">
          <p className="text-secondary">
            신규 프린터 생성
          </p>
        </Row>
        <Row className="bg-white pb-4">
          <Form>
            <Form.Group controlId="userid" className="mb-3">
              <Form.Label>가맹점</Form.Label>
              <Form.Select
                onChange={(e) => {
                  console.info(e.target.value)
                  setPrinterInfo({
                    ...printerInfo,
                    agencyId: e.target.value
                  })
                }}
              >
                <option value="">가맹점선택</option>
                {
                  agencies.map((agency: any) => {
                    return <option value={agency._id}>{agency.userName}</option>
                  })
                }
              </Form.Select>
              <Form.Text>
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="userName" className="mb-3">
              <Form.Label>프린터 이름</Form.Label>
              <Form.Control type="text"
                onChange={(e) => {
                  setPrinterInfo({
                    ...printerInfo,
                    name: e.target.value
                  })
                }}
              />
              <Form.Text className="text-muted">
                프린터 이름을 입력해주세요. (2자리 이상)
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="userName" className="mb-3">
              <Form.Label>IP 주소</Form.Label>
              <Form.Control type="text"
                onChange={(e) => {
                  setPrinterInfo({
                    ...printerInfo,
                    ipAddress: e.target.value.trim()
                  })
                }}
              />
            </Form.Group>


            <Form.Group controlId="userName" className="mb-3">
              <Form.Label>Port</Form.Label>
              <Form.Control type="text"
                value={printerInfo.port}
                maxLength={5}
                onChange={(e) => {
                  setPrinterInfo({
                    ...printerInfo,
                    port: e.target.value.trim().replace(/\D/gm, "")
                  })
                }}
              />
              <Form.Text className="text-muted">
                기본값은 9100입니다.
              </Form.Text>
            </Form.Group>

            <Row>
              <Col>
                <Button type="submit" variant="secondary"
                  onClick={() => {
                    return window.history.back();
                  }}>
                  뒤로가기
                </Button>

              </Col>
              <Col>
                <Button
                  className="float-end"
                  type="submit" onClick={(e) => {
                    e.preventDefault();
                    handleBtnClicked();
                  }}>
                  프린터생성
                </Button>
              </Col>
            </Row>
          </Form>
        </Row>
      </Container>
    </>
  )
}

export default AdminsHostsPrintersCreate