import { Button, Form, Container, Row, Col } from 'react-bootstrap'
import { useParams, useNavigate, Link } from 'react-router-dom'


import api from '@/api'
import { useEffect, useState } from 'react'

import dayjs from 'dayjs'

const AdminsUsersCustomersGet = () => {
  const navigation = useNavigate()
  const params = useParams();
  const [userInfo, setUserInfo] = useState({
    _id: '',
    userName: '',
    createdAt: '',
    userId: '',
    point: 0,
    phoneNumber: '',
    deleted: false,
    deletedAt: ''
  })

  useEffect(() => {
    api.admins.users.customers.getCustomer({ _id: params._id }).then((res: any) => {
      console.info(res)
      if (!res.error.ok) {
        alert("고객정보를 가져오는데 실패하였습니다")
        navigation(-1)
      } else {
        setUserInfo(res.result.customer)
      }
    }).catch((e) => {
      console.error(e)
      alert("서버오류가 발생하였습니다")
    })
  }, [params._id, navigation])

  return (
    <>
      <Container fluid>
        <Row className="mt-2">
          <p className="text-secondary">
            고객상세
          </p>
        </Row>

        <Row className="bg-white pb-4">
          <Form>
            <Form.Group controlId="id" className="mb-3">
              <Form.Label>고유키</Form.Label>
              <Form.Control type="text" value={userInfo._id} disabled />
            </Form.Group>

            <Form.Group controlId="userid" className="mb-3">
              <Form.Label>아이디(이메일)</Form.Label>
              <Form.Control type="text" value={userInfo.userId} disabled />
              <Form.Text>
                아이디 변경은 불가합니다
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="userid" className="mb-3">
              <Form.Label>가입일시</Form.Label>
              <Form.Control type="text" value={dayjs(userInfo.createdAt).format('YYYY-MM-DD HH:mm:ss')} disabled />
            </Form.Group>

            <Form.Group controlId="userid" className="mb-3">
              <Form.Label>탈퇴여부</Form.Label>
              <Form.Control type="text" value={userInfo.deleted ? "Y" : "N"} disabled />
            </Form.Group>

            <Form.Group controlId="userid" className="mb-3">
              <Form.Label>탈퇴일시</Form.Label>
              <Form.Control type="text" value={dayjs(userInfo.deletedAt).format('YYYY-MM-DD HH:mm:ss')} disabled />
            </Form.Group>

            <Form.Group controlId="userName" className="mb-3">
              <Form.Label>이름</Form.Label>
              <Form.Control type="text" value={userInfo.userName} />
            </Form.Group>

            <Form.Group controlId="phoneNumber" className="mb-3">
              <Form.Label>전화번호</Form.Label>
              <Form.Control type="text" value={userInfo.phoneNumber} />
            </Form.Group>

            <Form.Group controlId="phoneNumber" className="mb-3">
              <Form.Label>포인트</Form.Label>
              <Form.Control type="text" value={userInfo.point} disabled />
              <Form.Text>
                포인트를 지급하려면 쿠폰을 발행하세요&nbsp;
                <Link to="/admins/coupons/coupons/create">바로가기</Link>
              </Form.Text>
            </Form.Group>
          </Form>
          <Row>
            <Col>
              <Button type="submit" variant="secondary"
                onClick={() => {
                  navigation(-1)
                }}>
                뒤로가기
              </Button>
            </Col>
            <Col>
              {/* <Button
                className="float-end"
                type="submit" onClick={(e) => {
                  e.preventDefault();
                  handleBtnClicked();
                }}>
                쿠폰생성
              </Button> */}
            </Col>
          </Row>

        </Row>
      </Container>
    </>
  )
}

export default AdminsUsersCustomersGet