import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import { Button, Col, Container, Row } from 'react-bootstrap'

import Pagination from 'react-js-pagination'

import api from '@/api'
import { useEffect, useState } from 'react'

import dayjs from 'dayjs'
import { useNavigate, useLocation } from 'react-router-dom'
import queryString from 'query-string'


const AdminsUsersCustomersList = () => {
  const navigation = useNavigate()
  const { search } = useLocation()
  const [resultCount, setResultCount] = useState(0)
  const [query, setQuery] = useState('')
  const [page, setPage] = useState(1)
  const [results, setResults] = useState([])
  const [searchCount, setSearchCount] = useState(0)

  const fetch = (params: any) => {
    api.admins.users.customers.listCustomers({
      page: params.page,
      per_page: 10,
      query: params.query,
    }).then((res: any) => {
      setResults(res.result.customers)
      setSearchCount(res.result.total)
    }).catch((e) => {
      console.error(e)
      alert("서버오류가 발생하였습니다")
    })
  }

  const handlePageChange = (page: number) => {
    setPage(page)
    return window.location.href = (`/admins/users/customers/list?page=${page}&query=${query}`)
  }

  useEffect(() => {
    const values = queryString.parse(search)
    const queryPage = values.page ? Number(values.page) : 1
    const queryQuery = values.query ? String(values.query) : ''
    setPage(prevPage => queryPage)
    setQuery(prevQuery => queryQuery)
    api.admins.users.customers.countAllCustomers().then((res: any) => {
      setResultCount(res.result.count)
      fetch({ page: queryPage, query: queryQuery })
    }).catch((e) => {
      console.error(e)
      alert("서버오류가 발생하였습니다")
    })
  }, [search])


  return (
    <>
      <Container fluid>
        <Row className="mt-2">
          <p className="text-secondary">
            고객목록
          </p>
        </Row>
        <Row className="bg-white mb-4">
          <Row className="mt-2 mb-2">
            <Col>
              전체 고객: <b>{resultCount.toLocaleString()}</b>건 / 검색결과: <b>{searchCount.toLocaleString()}</b>건
            </Col>
          </Row>
          <Row className="mb-2">
            <Row className="mb-2">
              <Col sm={12} lg={8}>
                <Form.Control
                  type="text"
                  placeholder="검색어를 입력하세요(이름/전화번호/이메일)"
                  size="sm"
                  value={query}
                  onChange={(e) => { setQuery(e.target.value) }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  size="sm"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    return window.location.href = (`/admins/users/customers/list?page=1&query=${query}`)
                  }}
                >검색</Button>
              </Col>
            </Row>
          </Row>
        </Row>
        <Row className="bg-white pt-2 pb-2 ">
          <Col>
          </Col>
          <Col
            className="d-flex justify-content-end"

          >
          </Col>
        </Row>
        <Row className="bg-white">
          <Table bordered hover responsive="lg" className="table-sm">
            <thead>
              <tr>
                <th>이름</th>
                <th>이메일</th>
                <th>전화번호</th>
                <th>가입일</th>
                <th>탈퇴여부</th>
                <th>상세</th>
              </tr>
            </thead>
            <tbody>
              {
                results.map((customer: any) => {
                  return (
                    <tr>
                      <td>{customer.userName || ""}</td>
                      <td>{customer.userId || ""}</td>
                      <td>{customer.phoneNumber || ""}</td>

                      <td>{dayjs(customer.createdAt).format('YY. MM. DD.')}</td>
                      <td>{customer.deleted ? "탈퇴" : "-"}</td>
                      <td>
                        <Button
                          size="sm"
                          onClick={() => {
                            navigation(`/admins/users/customers/${customer._id}`)
                          }}
                        >
                          상세
                        </Button></td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
        </Row>
        <Row className="bg-white pb-4">
          <Col>
            <Pagination
              activePage={page}
              itemsCountPerPage={10}
              totalItemsCount={searchCount}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
            />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default AdminsUsersCustomersList