import {
  Col,
  Container,
  FloatingLabel,
  Form,
  FormGroup,
  Row,
  Button,
} from 'react-bootstrap';
import { useEffect, useState } from 'react';
import api from '@/api'



const LoginLayout = () => {
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [userType, setUserType] = useState('admin');

  const handleLoginBtnPressed = () => {
    if (userId === '' || password === '') {
      return alert("아이디와 비밀번호를 입력해주세요.")
    }
    return api.common.sessions.getSessionToken({
      userId, password, userType
    }).then((data: any) => {
      if (!data.error.ok) {
        console.error(data);
        alert(data.error.message)
      } else {
        console.info(`로그인 성공`)
        try {
          api.config.setToken(data.result.sessionToken)
        } catch (e) {
          console.error(e)
        }
        if (userType === 'admin') {
          api.config.setUserType('admin');
          window.location.href = ('/admins/users/customers')
        } else {
          api.config.setUserType('agency');
          window.location.href = ('/agencies/branches/codes')
        }
      }

    }).catch((e) => {
      console.error(e);
      return alert("서버에러가 발생하였습니다.")
    })
  }

  useEffect(() => {
    // api.health()
    console.log(
      `${process.env.REACT_APP_NAME} ${process.env.REACT_APP_VERSION}`
    );
    // api.common.session.getSessionToken().then((result) => { 
    // console.info(result)

  }, []);

  return (
    <>
      <Container className='d-flex bg-light vh-100 justify-content-center align-items-center' >
        <Row className="vw-100 align-items-center justify-content-center">
          <Col lg={10} md={10} sm={10} xs={10}>
            <div>
              <h3>Gongdream Office</h3>
              <h6 className='mb-3'>Ver. {process.env.REACT_APP_VERSION}</h6>
              <div>
                <div style={{ textAlign: 'center', alignItems: 'center' }}>
                  <Form>
                    <FormGroup>
                      <FloatingLabel
                        controlId='floatingInput'
                        label='username'
                        className='mb-3'
                      >
                        <Form.Control
                          type='text'
                          placeholder='username'
                          onChange={(e) => {
                            setUserId(e.target.value)
                          }}
                        />
                      </FloatingLabel>
                      <FloatingLabel
                        controlId='floatingPassword'
                        label='password'
                        className='mb-3'
                      >
                        <Form.Control
                          type='password'
                          placeholder='password'
                          onChange={(e) => {
                            setPassword(e.target.value)
                          }}
                        />
                      </FloatingLabel>
                    </FormGroup>
                    <Form.Select
                      className='mb-3'
                      defaultValue={userType}
                      onChange={(e) => {
                        setUserType(e.target.value)
                      }}
                    >
                      <option value="admin">관리자</option>
                      <option value="agency">가맹점</option>
                    </Form.Select>
                    <Button variant='primary' type='submit' size='lg'
                      onClick={(e) => {
                        e.preventDefault()
                        return handleLoginBtnPressed()
                      }}
                    >
                      로그인
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default LoginLayout;
