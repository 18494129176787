import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import { Button, Col, Container, Row } from 'react-bootstrap'

import Pagination from 'react-js-pagination'

import api from '@/api'
import { useEffect, useState } from 'react'

import dayjs from 'dayjs'
import { useNavigate, useLocation } from 'react-router-dom'
import queryString from 'query-string'


const AdminsHostsPrintersList = () => {
  const navigation = useNavigate()
  const { search } = useLocation()
  const [resultCount, setResultCount] = useState(0)
  const [query, setQuery] = useState('')
  const [page, setPage] = useState(1)
  const [results, setResults] = useState([])
  const [searchCount, setSearchCount] = useState(0)

  const fetch = (params: any) => {
    console.info(`fetch: page: ${params.page}`)
    console.info(`fetch: query: ${params.query}`)
    api.admins.hosts.printers.listPrinters({
      page: params.page,
      query: params.query,
    }).then((res: any) => {
      setResults(res.result.printers)
      setSearchCount(res.result.total)
    }).catch((e) => {
      console.error(e)
      alert("서버오류가 발생하였습니다")
    })
  }

  const handlePageChange = (page: number) => {
    setPage(page)
    return window.location.href = (`/admins/hosts/printers/list?page=${page}&query=${query}`)
  }

  useEffect(() => {
    const values = queryString.parse(search)
    const queryPage = values.page ? Number(values.page) : 1
    const queryQuery = values.query ? String(values.query) : ''
    console.info(`useEffect[page]: ${queryPage}`)
    console.info(`useEffect[query]: ${queryQuery}`)
    setPage(prevPage => queryPage)
    setQuery(prevQuery => queryQuery)
    api.admins.hosts.printers.countAllPrinters().then((res: any) => {
      setResultCount(res.result.count)
      fetch({ page: queryPage, query: queryQuery })
    }).catch((e) => {
      console.error(e)
      alert("서버오류가 발생하였습니다")
    })
  }, [search])


  return (
    <>
      <Container fluid>
        <Row className="mt-2">
          <p className="text-secondary">
            프린터목록
          </p>
        </Row>
        <Row className="bg-white mb-4">
          <Row className="mt-2 mb-2">
            <Col>
              전체 프린터: <b>{resultCount.toLocaleString()}</b>건 / 검색결과: <b>{searchCount.toLocaleString()}</b>건
            </Col>
          </Row>
          <Row className="mb-2">
            <Row className="mb-2">
              <Col sm={12} lg={8}>
                <Form.Control
                  type="text"
                  placeholder="검색어를 입력하세요(이름/IP/Port)"
                  size="sm"
                  value={query}
                  onChange={(e) => { setQuery(e.target.value) }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  size="sm"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    return window.location.href = (`/admins/hosts/printers/list?page=1&query=${query}`)
                  }}
                >검색</Button>
              </Col>
            </Row>
          </Row>
        </Row>
        <Row className="bg-white pt-2 pb-2 ">
          <Col>
          </Col>
          <Col
            className="d-flex justify-content-end"

          >
            <Button
              size="sm"
              className="align-self-end"
              onClick={() => {
                return window.location.href = ('/admins/hosts/printers/create')
              }}
            >
              신규프린터 생성
            </Button>

          </Col>
        </Row>
        <Row className="bg-white">

          <Table bordered hover responsive="lg" className="table-sm">
            <thead>
              <tr>
                <th>이름</th>
                <th>IP</th>
                <th>Port</th>
                {/* <th>고유번호</th> */}
                <th>가맹점명</th>
                <th>생성일</th>
                <th>수정</th>
              </tr>
            </thead>
            <tbody>
              {
                results.map((printer: any) => {
                  return (
                    <tr>
                      <td>{printer.name}</td>
                      <td>{printer.ipAddress}</td>
                      <td>{printer.port}</td>
                      {/* <td>{printer._id}</td> */}
                      <td>
                        <Button variant="secondary" size="sm"
                          onClick={() => { navigation(`/admins/users/agencies/${printer.agencyId}`) }}
                        >
                          {printer.agencyName}
                        </Button>
                      </td>
                      <td>{dayjs(printer.createdAt).format('YY. MM. DD.')}</td>
                      <td style={{ display: 'flex', gap: '10px' }}>
                        <Button
                          variant="warning"
                          size="sm"
                          onClick={() => { navigation(`/admins/hosts/printers/${printer._id}`) }}
                        >수정</Button>
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => {
                            alert('기능준비중')
                          }}
                        >삭제</Button>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
        </Row>
        <Row className="bg-white pb-4">
          <Col>
            <Pagination
              activePage={page}
              itemsCountPerPage={10}
              totalItemsCount={searchCount}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
            />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default AdminsHostsPrintersList