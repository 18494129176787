import { Container, Row, Col, Button, Form } from 'react-bootstrap'
import { useParams, useNavigate } from 'react-router-dom'

import api from '@/api'
import { useEffect, useState } from 'react'

const Coordinates = ({ visible, codeInfo, setCodeInfo, }: { visible: boolean, codeInfo: any, setCodeInfo: any }) => {
  if (!visible) return (<></>)
  else {
    return (
      <>
        <Form.Group controlId="userName" className="mb-3">
          <Form.Label>위도</Form.Label>
          <Form.Control type="text"
            defaultValue={codeInfo.coordinates[1]}
            onChange={(e) => {
              setCodeInfo({
                ...codeInfo,
                latitude: e.target.value
              })
            }}
          />
          <Form.Text>
            예) 37.123456
          </Form.Text>
        </Form.Group>

        <Form.Group controlId="userName" className="mb-3">
          <Form.Label>경도</Form.Label>
          <Form.Control type="text"
            defaultValue={codeInfo.coordinates[0]}
            onChange={(e) => {
              setCodeInfo({
                ...codeInfo,
                longitude: e.target.value
              })
            }}
          />
          <Form.Text>
            예) 127.123456
          </Form.Text>
        </Form.Group>
      </>
    )
  }
}




const AgenciesBranchesCodesGet = () => {
  const navigation = useNavigate()
  const params = useParams();


  const [codeInfo, setCodeInfo] = useState({
    agencyId: '',
    printerId: '',
    code: '',
    name: '',
    monoPricing: "",
    colorPricing: "",
    mobilePrinting: "false",
    latitude: '',
    longitude: '',
    coordinates: [0, 0],
  })

  const handleBtnClicked = () => {
    if (codeInfo.name === "") {
      return alert('코드명을 입력해주세요.')
    }
    if (codeInfo.monoPricing === "") {
      return alert('흑백 가격을 입력해주세요.')
    }
    if (isNaN(parseInt(codeInfo.monoPricing))) {
      return alert('올바른 흑백 가격을 입력해주세요.')
    }
    if (codeInfo.colorPricing === "") {
      return alert('컬러 가격을 입력해주세요.')
    }
    if (isNaN(parseInt(codeInfo.colorPricing))) {
      return alert('올바른 컬러 가격을 입력해주세요.')
    }
    if (JSON.parse(codeInfo.mobilePrinting)) {
      if (codeInfo.latitude === "") {
        return alert('위도를 입력해주세요.')
      }
      if (isNaN(parseFloat(codeInfo.latitude))) {
        return alert('올바른 위도를 입력해주세요.')
      }

      if (codeInfo.longitude === "") {
        return alert('경도를 입력해주세요.')
      }
      if (isNaN(parseFloat(codeInfo.longitude))) {
        return alert('올바른 경도를 입력해주세요.')
      }
    }

    const payload = {
      _id: params._id,
      name: codeInfo.name.trim(),
      monoPricing: parseInt(codeInfo.monoPricing),
      colorPricing: parseInt(codeInfo.colorPricing),
      mobilePrinting: JSON.parse(codeInfo.mobilePrinting) || false,
      latitude: JSON.parse(codeInfo.mobilePrinting) ? parseFloat(codeInfo.latitude) : 0,
      longitude: JSON.parse(codeInfo.mobilePrinting) ? parseFloat(codeInfo.longitude) : 0,
    }

    api.agencies.branches.codes.patchCode(payload).then((res: any) => {
      if (!res.error.ok) {
        console.info(res)
        return alert(res.error.message)
      } else {
        alert(`코드가 수정되었습니다.`)
        return window.location.href = (`/agencies/branches/codes/list`)
      }
    }).catch((e) => {
      console.error(e);
      alert(`에러가 발생하였습니다`);
    })

  }


  useEffect(() => {
    api.agencies.branches.codes.getCode({ _id: params._id }).then((res: any) => {
      const params = {
        ...res.result.code,
        coordinates: [
          res.result.code.coordinates[0] || 0,
          res.result.code.coordinates[1] || 0
        ],
        monoPricing: res.result.code.monoPricing.a4.toString(),
        colorPricing: res.result.code.colorPricing.a4.toString(),
        longitude: res.result.code.coordinates[0] || 0,
        latitude: res.result.code.coordinates[1] || 0,
      }
      console.info('a')
      console.info(params)
      setCodeInfo(params)
    }).catch((e) => {
      console.error(e);
      alert(`에러가 발생하였습니다`);
    });
  }, [params._id, navigation])

  useEffect(() => {
    console.info(`codeInfo modified`)
  }, [codeInfo])


  return (
    <>
      <Container fluid>
        <Row className="mt-2">
          <p className="text-secondary">
            코드상세
          </p>
        </Row>
        <Row className="bg-white pb-4">
          <Form>
            <Form.Group controlId="userName" className="mb-3">
              <Form.Label>코드</Form.Label>
              <Form.Control type="text"
                disabled
                defaultValue={codeInfo.code}
              />
              <Form.Text>
                코드 변경은 불가합니다. 코드 변경이 필요한 경우 신규 생성하여 이용하세요.
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="userName" className="mb-3">
              <Form.Label>코드명</Form.Label>
              <Form.Control type="text"
                defaultValue={codeInfo.name}
                onChange={(e) => {
                  setCodeInfo({
                    ...codeInfo,
                    name: e.target.value.trim()
                  })
                }}
              />
              <Form.Text>
                관리를 위한 코드명을 입력하세요<br />
                예시) 충북대 OO관 O층 O호기
              </Form.Text>
            </Form.Group>




            <Form.Group controlId="userName" className="mb-3">
              <Form.Label>요금(흑백)</Form.Label>
              <Form.Control type="text"
                defaultValue={codeInfo.monoPricing}
                maxLength={5}
                onChange={(e) => {
                  setCodeInfo({
                    ...codeInfo,
                    monoPricing: e.target.value.trim().replace(/\D/gm, "")
                  })
                }}
              />
              <Form.Text>
                숫자만 입력하세요 (원)
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="userName" className="mb-3">
              <Form.Label>요금(컬러)</Form.Label>
              <Form.Control type="text"
                defaultValue={codeInfo.colorPricing}
                maxLength={5}
                onChange={(e) => {
                  setCodeInfo({
                    ...codeInfo,
                    colorPricing: e.target.value.trim().replace(/\D/gm, "")
                  })
                }}
              />
              <Form.Text>
                숫자만 입력하세요 (원)
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="userid" className="mb-3">
              <Form.Label>모바일출력</Form.Label>
              <Form.Select
                value={codeInfo.mobilePrinting.toString()}
                onChange={(e) => {
                  setCodeInfo({
                    ...codeInfo,
                    mobilePrinting: e.target.value
                  })
                }}
              >
                <option value="false">미지원</option>
                <option value="true">지원</option>
              </Form.Select>
              <Form.Text>
              </Form.Text>
            </Form.Group>

            <Coordinates
              visible={JSON.parse(codeInfo.mobilePrinting)}
              codeInfo={codeInfo}
              setCodeInfo={setCodeInfo}
            />

            <Row>
              <Col>
                <Button type="submit" variant="secondary"
                  onClick={() => {
                    navigation(-1)
                  }}>
                  뒤로가기
                </Button>
              </Col>
              <Col>
                <Button
                  variant='warning'
                  className="float-end"
                  type="submit" onClick={(e) => {
                    e.preventDefault();
                    handleBtnClicked();
                  }}>
                  코드수정
                </Button>
              </Col>
            </Row>
          </Form>

        </Row>
      </Container>
    </>
  )
}

export default AgenciesBranchesCodesGet