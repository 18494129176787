import { Button, Form, Container, Row, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'


import api from '@/api'
import { useEffect, useState } from 'react'

import ModalSearchCustomers from '@/Components/Modal/SearchCustomers'

const AdminsCouponsCouponsCreate = () => {
  const navigation = useNavigate()

  const [modalCustomerSearch, setModalCustomerSearch] = useState(false)
  const [customerInfo, setCustomerInfo] = useState({
    _id: '',
    userId: '',
    userName: '',
    phoneNumber: '',
  })

  const [couponInfo, setCouponInfo] = useState({
    name: '',
    ipAddress: '',
    point: '0',
  })

  const handleBtnClicked = () => {
    if (!customerInfo._id) return alert('고객을 선택하세요.')
    if (!couponInfo.point) return alert('포인트를 입력하세요.')
    if (isNaN(parseInt(couponInfo.point, 10))) return alert('포인트는 숫자만 입력 가능합니다.')

    const params = {
      customerId: customerInfo._id,
      point: parseInt(couponInfo.point, 10),
    }
    return api.admins.coupons.coupons.createCoupon(params).then((res: any) => {
      if (!res.error.ok) {
        return alert(res.error.message)
      } else {
        alert("쿠폰이 발급되었습니다.")
        navigation('/admins/coupons/coupons/list')
      }
    }).catch((e) => {
      console.error(e)
      alert("서버오류가 발생하였습니다")
    })
    // if (printerInfo.agencyId == "")
    //   return alert('가맹점을 선택하세요.')

    // if (printerInfo.name.length < 2)
    //   return alert('프린터 이름을 2자리 이상 입력해주세요.')

    // if (!isIPv4(printerInfo.ipAddress))
    //   return alert(`IP주소가 올바르지 않습니다.`)

    // if (isNaN(parseInt(printerInfo.port)))
    //   return alert(`포트번호가 올바르지 않습니다.`)
    // if (parseInt(printerInfo.port) < 1 || parseInt(printerInfo.port) > 65535)
    //   return alert(`포트번호가 올바르지 않습니다.`)

    // const payload = {
    //   agencyId: printerInfo.agencyId.trim(),
    //   name: printerInfo.name.trim(),
    //   ipAddress: printerInfo.ipAddress.trim(),
    //   port: printerInfo.port.trim(),
    // }

    // return api.admins.hosts.printers.createPrinter(payload).then((res: any) => {
    //   if (!res.error.ok) {
    //     console.info(res)
    //     return alert(res.error.message)
    //   } else {
    //     alert('프린터가 생성되었습니다.')
    //     return window.location.href = (`/admins/hosts/printers/list`)
    //   }
    // }).catch((e) => {
    //   console.error(e);
    //   return alert(`에러가 발생하였습니다`);
    // })

  }

  useEffect(() => {
    api.admins.users.agencies.listAgencies({
      page: 1, perPage: 9999, query: ''
    }).then((res: any) => {
    }).catch((e) => {
      console.error(e);
      alert(`에러가 발생하였습니다`);
      return window.history.back();
    })

  }, [])


  return (
    <>
      <ModalSearchCustomers
        show={modalCustomerSearch}
        setShow={setModalCustomerSearch}
        setResult={setCustomerInfo}
      />
      <Container fluid>
        <Row className="mt-2">
          <p className="text-secondary">
            신규 쿠폰생성
          </p>
        </Row>
        <Row className="bg-white pb-4">
          <Row className='mt-3 mb-3 '>
            <Col className="col-4 text-muted">
              고객선택
            </Col>
            <Col className="col-8">
              <Button variant={customerInfo._id ? "primary" : "secondary"}
                onClick={() => setModalCustomerSearch(true)}
              >
                {
                  customerInfo._id === '' ? '고객선택' : customerInfo.userName + '\n' + customerInfo.userId + ''
                }
              </Button>
            </Col>
          </Row>
          <Row className="mb-3" >
            <Col className="col-4 text-muted">
              지급포인트
            </Col>
            <Col>
              <Form.Control type="number"
                defaultValue={couponInfo.point}
                maxLength={10}
                onChange={(e) => {
                  setCouponInfo({
                    ...couponInfo,
                    point: e.target.value.trim().replace(/\D/gm, "")
                  })
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Button type="submit" variant="secondary"
                onClick={() => {
                  navigation(-1)
                }}>
                뒤로가기
              </Button>
            </Col>
            <Col>
              <Button
                className="float-end"
                type="submit" onClick={(e) => {
                  e.preventDefault();
                  handleBtnClicked();
                }}>
                쿠폰생성
              </Button>
            </Col>
          </Row>
        </Row>
      </Container>
    </>
  )
}

export default AdminsCouponsCouponsCreate 