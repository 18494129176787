
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import { Button, Col, Container, Row, Alert } from 'react-bootstrap'


import api from '@/api'
import { useEffect, useState } from 'react'

import dayjs from 'dayjs'


interface iRange {
  text: string
  value: string
}

const AdminsPaymentsLogsByAgency = () => {
  const [agencies, setAgencies] = useState([])
  const [ranges, setRanges] = useState<iRange[]>([])
  const [agency, setAgency] = useState('all')
  const [targetDateStart, setTargetDateStart] = useState('all')
  const [targetDateEnd, setTargetDateEnd] = useState('all')
  const [results, setResults] = useState({
    "dateStart": "2022-01-01T00:00:00+09:00",
    "dateEnd": "2099-12-31T00:00:00+09:00",
    "total": 0,
    "cardAmount": 0,
    "point": 0
  })


  const handleBtnClick = () => {
    const params = { 
      agencyId: agency,
      dateStart: targetDateStart === "all" ? "2022-01-01" : dayjs(targetDateStart).tz().format('YYYY-MM-DD'),
      dateEnd: targetDateEnd === "all" ? "2099-12-31" : dayjs(targetDateEnd).tz().format('YYYY-MM-DD'),
    }
    console.info(params)
    return api.admins.payments.logs.getLogsByAgency(params).then((res: any) => {
      if (!res.error.ok) {
        alert(res.error.message)
        return
      } else {
        console.info(`response`)
        console.info(res.result)
        setResults({
          ...res.result,
          dateStart: res.result.dateStart === "all" ? "2022-01-01T00:00:00+09:00" : res.result.dateStart,
          dateEnd: res.result.dateEnd === "all" ? "2099-12-31T00:00:00+09:00" : res.result.dateEnd,
        })
      }
    }).catch((e) => {
      console.error(e)
      alert("서버오류가 발생하였습니다")
    })


  }

  useEffect(() => {
    setRanges([])
    for (let i = 2099; i > 2021; i--) {
      for (let k = 12; k > 0; k--) {
        let month: any = k;
        if (month < 10) { month = `0${month}` }
        const compareDate = dayjs().tz().format(`${i}-${month}-01T00:00:00+09:00`)
        const current = dayjs().tz().format('YYYY-MM-DDTHH:mm:ssZ')
        if (dayjs(compareDate).isBefore(current)) {
          setRanges(prevRanges => [
            ...prevRanges,
            {
              text: `${i}년 ${k}월`,
              value: `${i}-${k}-01`,
            },
          ])
        }
      }
    }
    api.admins.users.agencies.listAgencies({
      page: 1, perPage: 9999, query: ''
    }).then((res: any) => {
      setAgencies(res.result.agencies)
    }).catch((e) => {
      console.error(e);
      alert(`에러가 발생하였습니다`);
      return window.history.back();
    })

  }, [])

  useEffect(() => {
    if (targetDateStart === 'all') {
      setTargetDateEnd('all')
    } else {
      setTargetDateEnd(dayjs(targetDateStart).endOf('month').format('YYYY-MM-DD'))
    }
    console.info(`agency: ${agency} / targetDateStart: ${targetDateStart} / targetDateEnd: ${targetDateEnd}`)
  }, [agency, targetDateStart, targetDateEnd])


  return (
    <>
      <Container fluid>
        <Row className="mt-2">
          <p className="text-secondary">
            매출내역
          </p>
        </Row>
        <Row className="bg-white pt-2">
          <Col className="col-3">
            가맹점
          </Col>
          <Col className="col-9">
            <Form.Select
              defaultValue={"all"}
              onChange={(e) => { setAgency(e.target.value) }}
            >
              <option value="all">전체</option>
              {
                agencies.map((agency: any, index: number) => {
                  return (
                    <option key={index} value={agency._id}>{agency.userName}</option>
                  )
                })
              }
            </Form.Select>
          </Col>
        </Row>
        <Row className="bg-white pt-2">
          <Col className="col-3">
            조회일시
          </Col>
          <Col className="col-9">
            <Form.Select
              defaultValue={"all"}
              onChange={(e) => { setTargetDateStart(e.target.value) }}
            >
              <option value="all">전체기간</option>
              {
                ranges.map((range, index) => {
                  return (
                    <option key={index} value={range.value}>{range.text}</option>
                  )
                })
              }
            </Form.Select>
          </Col>
        </Row>
        <Row className="bg-white pt-2">
          <Col>
            <Button
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                return handleBtnClick();
              }}
            >검색</Button>
          </Col>
        </Row>

        <Row className="bg-white pt-2 pb-2 ">
          <Col>
          </Col>
          <Col
            className="d-flex justify-content-end"
          >
          </Col>
        </Row>
        <Row className="bg-white">
          <Container>
            <Alert variant='warning'>
              <li>
                실제 결제금액은 카드 취소 등 사유로 정확하지 않습니다. 정확한 금액 확인은 KICC에서 확인하세요.
              </li>
              <li>
                가맹점별 매출금액은 2022년 12월 20일 부터 집계됩니다. 이전 데이터는 KICC에서 확인해주세요.
              </li>
            </Alert>
          </Container>
        </Row>
        <Row className="bg-white pb-4">
          <Container>

            <Table bordered hover responsive="lg" className="table-sm">
              <thead>
                <tr>
                  <th>조회일시</th>
                  <th>총결제금액</th>
                  <th>카드결제</th>
                  <th>포인트</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {dayjs(results.dateStart).format('YYYY-MM-DD')} ~ {dayjs(results.dateEnd).format('YYYY-MM-DD')}
                  </td>
                  <td>
                    {results.total ? results.total.toLocaleString() : ""}원
                  </td>
                  <td>
                    {results.cardAmount ? results.cardAmount.toLocaleString() : ""}원
                  </td>
                  <td>
                    {results.point ? results.point.toLocaleString() : ""} P
                  </td>
                </tr>
              </tbody>

            </Table>
          </Container>
        </Row>
      </Container >
    </>
  )
}

export default AdminsPaymentsLogsByAgency