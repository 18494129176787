import React, { useEffect } from 'react'
import Navigation from '@/Components/Navigation'
import { Outlet } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { Container } from 'react-bootstrap'

const Layout = () => {
  const navigation = useNavigate()

  useEffect(() => {
    if (sessionStorage.getItem("token")) {
    } else {
      return navigation('/login')
    }
  })
  return (
    <>
      <div style={{ minWidth: '320px' }}>
        <Navigation />
        <Container fluid>
          <Outlet />
        </Container>
      </div>
    </>
  )
}

export default Layout