import { Container, Row, Col, Button, Form, Alert, } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'


import api from '@/api'
import { useEffect, useState } from 'react'


const Coordinates = ({ visible, codeInfo, setCodeInfo, }: { visible: boolean, codeInfo: object, setCodeInfo: any }) => {
  if (!visible) return (<></>)
  else {
    return (
      <>
        <Form.Group controlId="userName" className="mb-3">
          <Form.Label>위도</Form.Label>
          <Form.Control type="text"
            onChange={(e) => {
              setCodeInfo({
                ...codeInfo,
                latitude: e.target.value
              })
            }}
          />
          <Form.Text>
            예) 37.123456
          </Form.Text>
        </Form.Group>

        <Form.Group controlId="userName" className="mb-3">
          <Form.Label>경도</Form.Label>
          <Form.Control type="text"
            onChange={(e) => {
              setCodeInfo({
                ...codeInfo,
                longitude: e.target.value
              })
            }}
          />
          <Form.Text>
            예) 127.123456
          </Form.Text>
        </Form.Group>
      </>
    )
  }
}




const AdminsBranchesCodesCreate = () => {
  const navigation = useNavigate()

  const [agencies, setAgencies] = useState([])

  const [codeInfo, setCodeInfo] = useState({
    agencyId: '',
    printerId: '',
    code: '',
    name: '',
    monoPricing: "60",
    colorPricing: "200",
    mobilePrinting: "false",
    latitude: '',
    longitude: '',
  })
  const [printerList, setPrinterList] = useState([])

  const updatePrinterList = (agencyId: string) => {
    if (!agencyId) return;
    api.admins.hosts.printers.listPrintersByAgencyId({
      query: agencyId
    }).then((res: any) => {
      setPrinterList(res.result.printers)
    }).catch((e) => {
      console.error(e)
      setPrinterList([])
    })

  }

  const handleBtnClicked = () => {
    if (codeInfo.agencyId === "") {
      return alert(`가맹점을 선택하세요.`)
    }
    if (codeInfo.printerId === "") {
      return alert(`프린터를 선택하세요.`)
    }
    if (codeInfo.name === "") {
      return alert('코드명을 입력해주세요.')
    }
    if (codeInfo.code === "") {
      return alert('코드를 입력해주세요.')
    }
    if (codeInfo.monoPricing === "") {
      return alert('흑백 가격을 입력해주세요.')
    }
    if (isNaN(parseInt(codeInfo.monoPricing))) {
      return alert('올바른 흑백 가격을 입력해주세요.')
    }
    if (codeInfo.colorPricing === "") {
      return alert('컬러 가격을 입력해주세요.')
    }
    if (isNaN(parseInt(codeInfo.colorPricing))) {
      return alert('올바른 컬러 가격을 입력해주세요.')
    }
    if (JSON.parse(codeInfo.mobilePrinting)) {
      if (codeInfo.latitude === "") {
        return alert('위도를 입력해주세요.')
      }
      if (isNaN(parseFloat(codeInfo.latitude))) {
        return alert('올바른 위도를 입력해주세요.')
      }

      if (codeInfo.longitude === "") {
        return alert('경도를 입력해주세요.')
      }
      if (isNaN(parseFloat(codeInfo.longitude))) {
        return alert('올바른 경도를 입력해주세요.')
      }
    }

    const payload = {
      agencyId: codeInfo.agencyId.trim(),
      printerId: codeInfo.printerId.trim(),
      code: codeInfo.code.trim(),
      name: codeInfo.name.trim(),
      monoPricing: parseInt(codeInfo.monoPricing),
      colorPricing: parseInt(codeInfo.colorPricing),
      mobilePrinting: JSON.parse(codeInfo.mobilePrinting) || false,
      latitude: JSON.parse(codeInfo.mobilePrinting) ? parseFloat(codeInfo.latitude) : 0,
      longitude: JSON.parse(codeInfo.mobilePrinting) ? parseFloat(codeInfo.longitude) : 0,
    }

    api.admins.branches.codes.createCode(payload).then((res: any) => {
      if (!res.error.ok) {
        console.info(res)
        return alert(res.error.message)
      } else {
        alert(`코드가 생성되었습니다.`)
        return window.location.href = (`/admins/branches/codes/list`)
      }
    }).catch((e) => {
      console.error(e);
      alert(`에러가 발생하였습니다`);
    })

  }


  useEffect(() => {
    api.admins.users.agencies.listAgencies({
      page: 1, perPage: 9999, query: ''
    }).then((res: any) => {
      setAgencies(res.result.agencies)
    }).catch((e) => {
      console.error(e);
      alert(`에러가 발생하였습니다`);
      return window.history.back();
    })

  }, [])

  useEffect(() => {
    console.info(`codeInfo modified`)
    console.info(codeInfo)

  }, [codeInfo])


  return (
    <>
      <Container fluid>
        <Row className="mt-2">
          <p className="text-secondary">
            신규코드 생성
          </p>
        </Row>
        <Alert key={"info"} variant="warning">
          코드를 생성하기전 가맹점과 프린터가 등록되어 있어야 합니다.
        </Alert>
        <Row className="bg-white pb-4">
          <Form>
            <Form.Group controlId="userid" className="mt-3 mb-3">
              <Form.Label>담당 가맹점</Form.Label>
              <Form.Select
                onChange={(e) => {
                  setCodeInfo({
                    ...codeInfo,
                    agencyId: e.target.value
                  })
                  return updatePrinterList(e.target.value);
                }}
              >
                <option value="">가맹점선택</option>
                {
                  agencies.map((agency: any) => {
                    return <option value={agency._id}>{agency.userName}</option>
                  })
                }
              </Form.Select>
              <Form.Text>
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="userName" className="mb-3">
              <Form.Label>프린터 선택</Form.Label>
              <Form.Select
                disabled={
                  codeInfo.agencyId ? false : true
                }
                onChange={(e) => {
                  setCodeInfo({
                    ...codeInfo,
                    printerId: e.target.value
                  })
                }}
              >
                <option value="">프린터선택</option>
                {
                  printerList.map((printer: any) => {
                    return <option value={printer._id}>{printer.name} ({printer.ipAddress})</option>
                  })
                }
              </Form.Select>
              <Form.Text>
                담당가맹점을 먼저 선택하세요
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="userName" className="mb-3">
              <Form.Label>코드명</Form.Label>
              <Form.Control type="text"
                onChange={(e) => {
                  setCodeInfo({
                    ...codeInfo,
                    name: e.target.value.trim()
                  })
                }}
              />
              <Form.Text>
                관리를 위한 코드명을 입력하세요<br />
                예시) 충북대 OO관 O층 O호기
              </Form.Text>
            </Form.Group>



            <Form.Group controlId="userName" className="mb-3">
              <Form.Label>코드</Form.Label>
              <Form.Control type="text"
                onChange={(e) => {
                  setCodeInfo({
                    ...codeInfo,
                    code: e.target.value.trim()
                  })
                }}
              />
              <Form.Text>
                [가맹점]-[건물번호]-[설치층]-[순번] 형태 코드를 권장합니다.<br />
                예시) 7-1-1-1
              </Form.Text>
            </Form.Group>


            <Form.Group controlId="userName" className="mb-3">
              <Form.Label>요금(흑백)</Form.Label>
              <Form.Control type="text"
                defaultValue={codeInfo.monoPricing}
                maxLength={5}
                onChange={(e) => {
                  setCodeInfo({
                    ...codeInfo,
                    monoPricing: e.target.value.trim().replace(/\D/gm, "")
                  })
                }}
              />
              <Form.Text>
                숫자만 입력하세요 (원)
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="userName" className="mb-3">
              <Form.Label>요금(컬러)</Form.Label>
              <Form.Control type="text"
                defaultValue={codeInfo.colorPricing}
                maxLength={5}
                onChange={(e) => {
                  setCodeInfo({
                    ...codeInfo,
                    colorPricing: e.target.value.trim().replace(/\D/gm, "")
                  })
                }}
              />
              <Form.Text>
                숫자만 입력하세요 (원)
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="userid" className="mb-3">
              <Form.Label>모바일출력</Form.Label>
              <Form.Select
                onChange={(e) => {
                  setCodeInfo({
                    ...codeInfo,
                    mobilePrinting: e.target.value
                  })
                }}
              >
                <option value="false">미지원</option>
                <option value="true">지원</option>
              </Form.Select>
              <Form.Text>
              </Form.Text>
            </Form.Group>

            <Coordinates
              visible={JSON.parse(codeInfo.mobilePrinting)}
              codeInfo={codeInfo}
              setCodeInfo={setCodeInfo}
            />

            <Row>
              <Col>
                <Button type="submit" variant="secondary"
                  onClick={() => {
                    navigation(-1)
                  }}>
                  뒤로가기
                </Button>
              </Col>
              <Col>
                <Button
                  className="float-end"
                  type="submit" onClick={(e) => {
                    e.preventDefault();
                    handleBtnClicked();
                  }}>
                  코드생성
                </Button>
              </Col>
            </Row>
          </Form>

        </Row>
      </Container>
    </>
  )
}

export default AdminsBranchesCodesCreate